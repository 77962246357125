
import { defineComponent } from 'vue'
import { ElMessage, ElLoading, ElMessageBox } from 'element-plus'
import { openContractDetail } from '@/api/contract'
import axios from 'axios'
import {
    payrollRecord,
    stopPay,
    PayrollRecordDto,
    payScreenType
} from '@/api/pay'
import { payrollRecordExport } from '@/api/export'
import { shortcuts } from '@/utils/global'
import { payrollRecordEle, downloadPayrollRecordEle } from '@/api/operation'
import { EnterpriseDto, getEnterpriseNameList } from '@/api/enterprise'
import { dateFormat, closewin, downLoadFile } from '@/utils/util'
import store from '@/store/index'
import router from '@/router'
export default defineComponent({
    name: 'PayrollRecord',
    data() {
        return {
            active: '全部',
            total: 0,
            pageSize: 10,
            pageNum: 1,
            tableData: [] as PayrollRecordDto[],
            payDateSolt: [] as string[],
            paidTime: '', // 领薪时间
            searchValue: '',
            searchText: '',
            selects: [] as string[],
            shortcuts: shortcuts,
            enterpriseName: '',
            enterpriseList: [] as EnterpriseDto[], // 派遣单位列表
            payTypeList: [] as { dictLabel: string; dictValue: string }[],
            payType: '' as number | string,
            searchType: 0
        }
    },
    created() {
        // 被跳转到该页面恢复原页面数据
        this.payDateSolt = [(dateFormat('YY-mm', new Date()) + '-01'), dateFormat('YY-mm-dd', new Date())]
        console.log(this.payDateSolt, 'payDateSolt')
        if (/tip/.test(router.options.history.base)) {
            ElMessageBox.alert('回单正在下载中，您可以在该界面继续操作')
            const channel = new BroadcastChannel('new_tab_payroll')
            let isLoad = 0
            channel.onmessage = e => {
                const data = JSON.parse(e.data)
                this.enterpriseName = data.enterpriseName
                this.pageNum = data.pageNum
                this.pageSize = data.pageSize
                this.paidTime = data.paidTime
                this.payDateSolt = data.payDateSolt
                this.payType = data.payType
                this.searchValue = data.searchValue
                this.searchText = data.searchText
                isLoad = 1
                channel.postMessage('payroll_close')
                channel.close()
                this.init()
            }
            setTimeout(() => {
                if (!isLoad) {
                    window.location.search = ''
                }
                channel.close()
            }, 5000)
        } else {
            this.init()
        }
    },
    methods: {
        init() {
            this.handleCurrentChange()
            this.getEnterpriseNameList()
            this.getPayScreenType()
        },
        windowOpen() {
            window.open(
                window.location.pathname +
                '?tip#/payrollrecord'
            )
            const channel = new BroadcastChannel('new_tab_payroll')
            const timer = setInterval(() => {
                channel.postMessage(JSON.stringify({
                    payDateSolt: this.payDateSolt,
                    paidTime: this.paidTime,
                    searchValue: this.searchValue,
                    pageSize: this.pageSize,
                    pageNum: this.pageNum,
                    enterpriseName: this.enterpriseName,
                    payType: this.payType,
                    searchText: this.searchText
                }))
                console.log('postMessage')
            }, 50)
            channel.onmessage = e => {
                if (e.data === 'payroll_close') {
                    clearInterval(timer)
                    channel.close()
                }
            }
        },
        // 查询公司下派遣单位列表
        async getEnterpriseNameList() {
            const result = await getEnterpriseNameList()
            this.enterpriseList = result.list
        },
        handleCurrentChange() {
            console.log(this.payDateSolt, 'payDateSolt')
            payrollRecord({
                searchText: this.searchType ? '' : this.searchValue,
                beginPayTime: this.payDateSolt ? this.payDateSolt[0] : '',
                endPayTime: this.payDateSolt ? this.payDateSolt[1] : '',
                beginGetSalaryTime: this.paidTime ? this.paidTime[0] : '',
                endGetSalaryTime: this.paidTime ? this.paidTime[1] : '',
                pageSize: this.pageSize,
                pageNum: this.pageNum,
                serveCompanyId: this.enterpriseName,
                status: this.payType === 'ALL' ? '' : this.payType,
                detailRemarks: this.searchType ? this.searchValue : ''
            }).then(res => {
                this.tableData = res.list.map(item => {
                    item.userName =
                        item.userName +
                        '(' +
                        (item.idCardNo + '').slice(14) +
                        ')'
                    return item
                })
                this.total = res.total
                console.log(res.list, 'resres')
            })
        },
        // 批量停止发薪
        async stopPay() {
            await stopPay(this.selects.join(','))
            ElMessage.error('停止发薪成功')
            await this.handleCurrentChange()
        },
        // 一键申请回单
        applyReceipt() {
            payrollRecordEle({
                ids: this.selects.join(','),
                beginPayTime: this.payDateSolt ? this.payDateSolt[0] : '',
                endPayTime: this.payDateSolt ? this.payDateSolt[1] : '',
                beginGetSalaryTime: this.paidTime ? this.paidTime[0] : '',
                endGetSalaryTime: this.paidTime ? this.paidTime[1] : ''
            }).then(() => {
                ElMessage.success('申请成功')
                this.handleCurrentChange()
            })
        },
        // 下载申请回单 downloadPayrollRecordEle
        async downloadPayrollRecordEle() {
            // this.downloadReceipt()
            this.downloadReceiptOld()
        },
        async downloadReceiptOld() {
            const result = await downloadPayrollRecordEle({
                ids: this.selects.join(',')
            })
            this.windowOpen()

            document.title = '正在下载中...'
            const loading = await ElLoading.service({
                fullscreen: true,
                text: '回单正在下载中，您可以在新界面继续操作'
            })
            const files = await axios({
                url:
                    'https://1403244436326619.cn-shanghai.fc.aliyuncs.com/2016-08-15/proxy/zip-service/zip-oss/',
                method: 'post',
                data: result
            })
            window.location.href = files.data
            await this.$message.success('导出成功！')
            await loading.close()
            closewin()
        },

        async downloadReceipt() {
            const result = await downloadPayrollRecordEle({
                ids: this.selects.join(',')
            })
            this.windowOpen()
            document.title = '正在下载中...'
            const loading = await ElLoading.service({
                fullscreen: true,
                text: '回单正在下载中，您可以在新界面继续操作'
            })
            console.log(result, 'result')
            const files = await axios({
                url:
                    'https://1403244436326619.cn-shanghai.fc.aliyuncs.com/2016-08-15/proxy/zip-oss/zip-oss/',
                method: 'post',
                responseType: 'blob',
                data: result
            })
            // window.location.href =
            // process.env.VUE_APP_BASE_URL +
            // '/common/downloadBatchBill?delete=true&uuid=' +
            // str
            console.log(files, 'files')
            const blob = new Blob([files.data])
            const downloadElement = document.createElement('a')
            const href = window.URL.createObjectURL(blob)
            downloadElement.href = href
            downloadElement.download =
                '回单' + dateFormat('YY-mm-dd HH:MM', new Date()) + '.zip'
            document.body.appendChild(downloadElement)
            downloadElement.click()
            document.body.removeChild(downloadElement)
            window.URL.revokeObjectURL(href)

            await loading.close()
            await this.$message.success('导出成功！')
            closewin()
        },
        handleSizeChange() {
            this.pageNum = 1
            this.handleCurrentChange()
        },
        search() {
            this.searchValue = this.searchText
            this.pageNum = 1
            this.handleCurrentChange()
        },
        onReset() {
            this.searchText = ''
            this.payDateSolt = []
            this.enterpriseName = ''
            this.search()
        },
        async onExport() {
            const result = await payrollRecordExport({
                ids: this.selects.join(','),
                searchText: this.searchValue,
                serveCompanyId: this.enterpriseName,
                beginPayTime: this.payDateSolt ? this.payDateSolt[0] : '',
                endPayTime: this.payDateSolt ? this.payDateSolt[1] : '',
                beginGetSalaryTime: this.paidTime ? this.paidTime[0] : '',
                endGetSalaryTime: this.paidTime ? this.paidTime[1] : '',
                status: this.payType === 'ALL' ? '' : this.payType
            }, {
                loading: true
            })
            console.log(result, 'result')
            if (!result.fileUrl && result.exportStatus === 0) {
                store.commit('getLongPollingFile', result)
            } else {
                axios({
                    url: result.fileUrl,
                    method: 'GET',
                    responseType: 'blob'
                }).then(async (response) => {
                    await downLoadFile(response, result.exportType, result.updateTime, result.remark)
                    await this.$message.success('导出成功！')
                })
            }
            // window.location.href =
            //     process.env.VUE_APP_BASE_URL +
            //     '/common/download?delete=true&fileName=' +
            //     result
            // this.$message.success('导出成功！')
        },
        handleSelectionChange(selects: { id: string }[]) {
            this.selects = selects.map(item => item.id)
            console.log(this.selects)
        },
        async goDetail(flowId: string) {
            const result = await openContractDetail(flowId)
            window.open(result.consoleUrl)
        },
        getPayScreenType() {
            payScreenType('saas-pay-get-status').then(res => {
                this.payTypeList = res
            })
        }
    }
})
