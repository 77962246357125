import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_searchComponent = _resolveComponent("searchComponent")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_tableComponent = _resolveComponent("tableComponent")!
  const _component_el_card = _resolveComponent("el-card")!

  return (_openBlock(), _createBlock(_component_el_card, { class: "container" }, {
    default: _withCtx(() => [
      _createVNode(_component_searchComponent, {
        flex: "",
        "search-list": _ctx.searchList,
        "onUpdate:searchList": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchList) = $event)),
        "button-list": _ctx.buttonList,
        onDoSearch: _ctx.handleSearch
      }, null, 8, ["search-list", "button-list", "onDoSearch"]),
      _createVNode(_component_tableComponent, {
        class: "mg-t-20",
        "table-data": _ctx.tableData,
        "table-head": _ctx.tableHead,
        onSelectionChange: _ctx.handleSelectionChange,
        "page-info": _ctx.pageInfo,
        onSizeChange: _ctx.handleSizeChange,
        onCurrentChange: _ctx.handleCurrentChange
      }, {
        operate: _withCtx((scope) => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_el_button, {
              type: "text",
              size: "small",
              onClick: _withModifiers(($event: any) => (_ctx.handleArchive(scope.row)), ["stop"])
            }, {
              default: _withCtx(() => _cache[1] || (_cache[1] = [
                _createTextVNode("详情")
              ])),
              _: 2
            }, 1032, ["onClick"]),
            _createVNode(_component_el_button, {
              type: "text",
              size: "small",
              onClick: ($event: any) => (_ctx.handleExport(scope.row))
            }, {
              default: _withCtx(() => _cache[2] || (_cache[2] = [
                _createTextVNode("导出明细")
              ])),
              _: 2
            }, 1032, ["onClick"])
          ])
        ]),
        _: 1
      }, 8, ["table-data", "table-head", "onSelectionChange", "page-info", "onSizeChange", "onCurrentChange"])
    ]),
    _: 1
  }))
}