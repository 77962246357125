import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, vModelText as _vModelText, withKeys as _withKeys, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "header block" }
const _hoisted_2 = { class: "detail" }
const _hoisted_3 = { class: "search" }
const _hoisted_4 = ["placeholder"]
const _hoisted_5 = { class: "container block" }
const _hoisted_6 = { class: "tool" }
const _hoisted_7 = { class: "table" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { class: "nickName" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_export_button = _resolveComponent("export-button")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_pagination = _resolveComponent("el-pagination")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _cache[11] || (_cache[11] = _createTextVNode(" 发薪时间 ")),
          _createVNode(_component_el_date_picker, {
            modelValue: _ctx.payDateSolt,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.payDateSolt) = $event)),
            type: "daterange",
            class: "date-solt screen-i",
            "unlink-panels": "",
            "range-separator": "至",
            "start-placeholder": "开始日期",
            "end-placeholder": "结束日期",
            onChange: _ctx.handleCurrentChange,
            "value-format": "YYYY-MM-DD",
            shortcuts: _ctx.shortcuts
          }, null, 8, ["modelValue", "onChange", "shortcuts"]),
          _cache[12] || (_cache[12] = _createTextVNode(" 领薪时间 ")),
          _createVNode(_component_el_date_picker, {
            modelValue: _ctx.paidTime,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.paidTime) = $event)),
            type: "daterange",
            class: "date-solt screen-i",
            "unlink-panels": "",
            "range-separator": "至",
            "start-placeholder": "开始日期",
            "end-placeholder": "结束日期",
            onChange: _ctx.handleCurrentChange,
            "value-format": "YYYY-MM-DD",
            shortcuts: _ctx.shortcuts
          }, null, 8, ["modelValue", "onChange", "shortcuts"]),
          _createVNode(_component_el_select, {
            modelValue: _ctx.searchType,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.searchType) = $event)),
            filterable: "",
            placeholder: "搜索方式",
            class: "screen-i search-type"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_option, {
                label: "搜名称",
                value: 0
              }),
              _createVNode(_component_el_option, {
                label: "搜备注",
                value: 1
              })
            ]),
            _: 1
          }, 8, ["modelValue"]),
          _withDirectives(_createElementVNode("input", {
            type: "text",
            placeholder: _ctx.searchType ? '请输入备注信息' : '请输入人员名称',
            class: "input search-input screen-i",
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.searchText) = $event)),
            style: {"width":"150px"},
            onKeydown: _cache[4] || (_cache[4] = _withKeys(
//@ts-ignore
(...args) => (_ctx.search && _ctx.search(...args)), ["enter"]))
          }, null, 40, _hoisted_4), [
            [_vModelText, _ctx.searchText]
          ]),
          _createVNode(_component_el_select, {
            modelValue: _ctx.payType,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.payType) = $event)),
            filterable: "",
            placeholder: "筛选领薪状态",
            class: "screen-i drop-down",
            onChange: _ctx.handleCurrentChange
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.payTypeList, (item, index) => {
                return (_openBlock(), _createBlock(_component_el_option, {
                  label: item.dictLabel,
                  value: item.dictValue,
                  key: index
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.dictLabel), 1)
                  ]),
                  _: 2
                }, 1032, ["label", "value"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["modelValue", "onChange"]),
          _createVNode(_component_el_select, {
            modelValue: _ctx.enterpriseName,
            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.enterpriseName) = $event)),
            filterable: "",
            placeholder: "请选择派遣单位",
            class: "screen-i"
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.enterpriseList, (item, index) => {
                return (_openBlock(), _createBlock(_component_el_option, {
                  label: item.serveCompanyName,
                  value: item.id,
                  key: index
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.serveCompanyName), 1)
                  ]),
                  _: 2
                }, 1032, ["label", "value"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["modelValue"]),
          _createElementVNode("div", null, [
            _createElementVNode("button", {
              class: "btn screen-i",
              onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.search && _ctx.search(...args)))
            }, "搜索"),
            _createElementVNode("button", {
              class: "btn-white screen-i",
              onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.onReset && _ctx.onReset(...args)))
            }, " 重置 ")
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("view", _hoisted_6, [
        _createVNode(_component_el_button, {
          onClick: _ctx.stopPay,
          style: {"margin-right":"13px","background":"#FF7E68","color":"#fff"},
          disabled: _ctx.selects.length === 0
        }, {
          default: _withCtx(() => _cache[13] || (_cache[13] = [
            _createElementVNode("i", { class: "el-icon el-icon-remove" }, null, -1),
            _createTextVNode(" 停止发薪 ")
          ])),
          _: 1
        }, 8, ["onClick", "disabled"]),
        _createVNode(_component_export_button, {
          type: "success",
          onExport: _ctx.onExport
        }, null, 8, ["onExport"]),
        _createVNode(_component_el_button, {
          class: "btn-white download-btn",
          onClick: _ctx.applyReceipt,
          style: {"width":"160px","margin-left":"10px","border":"1px solid #1989FA","color":"#1989FA"}
        }, {
          default: _withCtx(() => _cache[14] || (_cache[14] = [
            _createElementVNode("i", { class: "el-icon el-icon-download" }, null, -1),
            _createTextVNode(" 一键申请回单 ")
          ])),
          _: 1
        }, 8, ["onClick"]),
        _createVNode(_component_el_button, {
          class: "btn-white download-btn",
          onClick: _ctx.downloadPayrollRecordEle,
          style: {"width":"180px","margin-left":"10px","border":"1px solid #00D679","color":"#00D679"},
          disabled: _ctx.selects.length === 0
        }, {
          default: _withCtx(() => _cache[15] || (_cache[15] = [
            _createElementVNode("i", { class: "el-icon el-icon-download" }, null, -1),
            _createTextVNode(" 一键下载微信回单 ")
          ])),
          _: 1
        }, 8, ["onClick", "disabled"])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_el_table, {
          data: _ctx.tableData,
          style: {"width":"100%"},
          "header-row-style": { color: '#202536' },
          "row-style": { color: '#5E617D' },
          onSelectionChange: _ctx.handleSelectionChange
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_table_column, { type: "selection" }),
            _createVNode(_component_el_table_column, {
              prop: "userName",
              label: "用户名",
              width: "120"
            }, {
              default: _withCtx((scope) => [
                _createElementVNode("div", null, [
                  _createElementVNode("div", null, _toDisplayString(scope.row.userName), 1),
                  (scope.row.nickName)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                        _createVNode(_component_el_tooltip, {
                          class: "box-item",
                          effect: "dark",
                          content: "此处为备注名",
                          placement: "top-start"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_9, _toDisplayString(scope.row.nickName ? '(' + scope.row.nickName + ')' : ''), 1)
                          ]),
                          _: 2
                        }, 1024)
                      ]))
                    : _createCommentVNode("", true)
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_el_table_column, {
              prop: "serveCompanyName",
              label: "派遣单位名称"
            }),
            _createVNode(_component_el_table_column, {
              prop: "projectName",
              label: "项目名称"
            }),
            _createVNode(_component_el_table_column, {
              prop: "paymentAmount",
              label: "支付金额",
              formatter: (row) => ('￥' + row.paymentAmount)
                    
            }, null, 8, ["formatter"]),
            _createVNode(_component_el_table_column, {
              prop: "payTime",
              label: "发薪时间"
            }),
            _createVNode(_component_el_table_column, {
              prop: "auditStatus",
              label: "审核状态",
              formatter: (row) => (row.auditStatus === 1 ? '审核通过' : row.auditStatus === 2 ? '审核失败' : '审核中')
                    
            }, null, 8, ["formatter"]),
            _createVNode(_component_el_table_column, {
              prop: "statusMsg",
              label: "领薪状态"
            }),
            _createVNode(_component_el_table_column, {
              prop: "getSalaryTime",
              label: "领薪时间"
            }),
            _createVNode(_component_el_table_column, {
              prop: "billStatus",
              label: "回单状态",
              formatter: (row) => (row.billStatus === null ? '未申请' : row.billStatus === 1 ? '申请中' : row.billStatus === 2 ? '已完成' : '未申请')
                    
            }, null, 8, ["formatter"]),
            _createVNode(_component_el_table_column, {
              prop: "detailRemarks",
              label: "备注"
            }),
            _createVNode(_component_el_table_column, {
              prop: "flowCode",
              label: "流程单号",
              width: "200px"
            })
          ]),
          _: 1
        }, 8, ["data", "onSelectionChange"]),
        _createVNode(_component_el_pagination, {
          class: "pagination",
          currentPage: _ctx.pageNum,
          "onUpdate:currentPage": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.pageNum) = $event)),
          "page-size": _ctx.pageSize,
          "onUpdate:pageSize": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.pageSize) = $event)),
          "page-sizes": [10, 20, 30, 50],
          layout: "total, sizes, prev, pager, next, jumper",
          total: _ctx.total,
          onSizeChange: _ctx.handleSizeChange,
          onCurrentChange: _ctx.handleCurrentChange
        }, null, 8, ["currentPage", "page-size", "total", "onSizeChange", "onCurrentChange"])
      ])
    ])
  ], 64))
}