
import { defineComponent } from 'vue'
import infoStaff from './components/info-staff.vue'
import detailStaffSalary from './components/detail-staff-salary.vue'
export default defineComponent({
    components: {
        infoStaff,
        detailStaffSalary
    },
    data() {
        return {
            id: '',
            cardId: '',
            queryMonth: ''
        }
    },
    mounted() {
        if (this.$route.query?.id) {
            this.id = atob(this.$route.query.id as any)
        }
        if (this.$route.query?.cardId) {
            this.cardId = atob(this.$route.query.cardId as any)
        }
        if (this.$route.query?.queryMonth) {
            this.queryMonth = atob(this.$route.query.queryMonth as any)
        }
    },
    methods: {
        onDateChange(ev: string) {
            this.queryMonth = ev
        }
    }
})
