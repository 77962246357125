import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tableComponent = _resolveComponent("tableComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_tableComponent, {
      class: "mg-t-20",
      "table-data": _ctx.tableData,
      "table-head": _ctx.tableHead,
      "row-key": "id",
      onSelectionChange: _ctx.handleSelectionChange,
      "page-info": _ctx.pageInfo,
      onSizeChange: _ctx.handleSizeChange,
      onCurrentChange: _ctx.handleCurrentChange
    }, {
      status: _withCtx((scope) => [
        _createElementVNode("div", {
          class: _normalizeClass([`icon-${scope.row.status}`])
        }, _toDisplayString(_ctx.statusMap[scope.row.status]), 3)
      ]),
      _: 1
    }, 8, ["table-data", "table-head", "onSelectionChange", "page-info", "onSizeChange", "onCurrentChange"])
  ]))
}