
import { defineComponent } from 'vue'
import axios from 'axios'
import dayjs from 'dayjs'
import store from '@/store/index'
import searchComponent from '@/components/search-component/index.vue'
import tableComponent from '@/components/table-component/index.vue'
import { getEmployeeSalary, exportEmployeeSalaryRecord } from '@/api/salary'
import { payrollRecordOwnExport } from '@/api/export'
import { dateFormat, downLoadFile } from '@/utils/util'

export default defineComponent({
  name: 'PayrollRecordOwn',
  components: {
    searchComponent,
    tableComponent
  },
  data() {
    return {
      searchList: {
        queryMonth: {
          name: '发薪月份',
          prop: 'queryMonth',
          type: 'date',
          dateType: 'month',
          placeholder: '开始时间',
          value: dayjs().format('YYYY-MM'),
          defaultValue: dayjs().format('YYYY-MM'),
          valueFormat: 'YYYY-MM',
          unClearable: true
        },
        searchText: {
          name: '搜索',
          prop: 'searchText',
          type: 'input',
          placeholder: '请输入员工信息',
          value: '',
          defaultValue: ''
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } as any,
      buttonList: [
        {
          name: '导出明细',
          icon: 'export',
          fn: () => {
            this.onExport()
          }
        }
      ] as any,
      tableHead: [
        {
          prop: 'index'
        },
        {
          prop: 'date',
          label: '月份'
        },
        {
          prop: 'userName',
          label: '姓名'
        },
        {
          prop: 'phone',
          label: '手机号',
          width: 120
        },
        {
          prop: 'idCardNo',
          label: '身份证号',
          width: 180
        },
        {
          prop: 'totalPayCount',
          label: '发薪单数'
        },
        {
          prop: 'realHairAmount',
          label: '实发金额',
          prefix: '¥'
        },
        {
          prop: 'stopPay',
          label: '停发金额',
          prefix: '¥'
        },
        {
          prop: 'received',
          label: '已领金额',
          prefix: '¥'
        },
        {
          prop: 'unclaimed',
          label: '待领金额',
          prefix: '¥'
        },
        {
          prop: 'operate',
          label: '操作',
          width: 160,
          fixed: 'right'
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ] as any,
      tableData: [
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ] as any,
      pageInfo: {
        pageNum: 1,
        pageSize: 10,
        total: 0
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } as any,
      selects: [],
      ids: []
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    handleSelectionChange(row: any) {
      this.selects = row.map((i: any) => i.idCardNo)
      this.ids = row.map((i: any) => i.id)
    },
    createQuery(hasPage = true) {
      const query = {} as any
      if (hasPage) {
        query.pageNum = this.pageInfo.pageNum
        query.pageSize = this.pageInfo.pageSize
      }
      for (const key in this.searchList) {
        if (this.searchList[key].type === 'dateRange') {
          query[`begin${this.searchList[key].prop}`] = this.searchList[key].value?.length ? this.searchList[key].value[0] : ''
          query[`end${this.searchList[key].prop}`] = this.searchList[key].value?.length ? this.searchList[key].value[1] : ''
        } else {
          query[(this.searchList[key]).prop] = this.searchList[key].value
        }
      }
      return query
    },
    handleSearch() {
      this.pageInfo.pageNum = 1
      this.getData()
    },
    handleSizeChange(ev: number) {
      this.pageInfo.pageSize = ev
      this.getData()
    },
    handleCurrentChange(ev: number) {
      this.pageInfo.pageNum = ev
      this.getData()
    },
    handleArchive(row: any) {
      this.$router.push({
        path: '/staffBillDetail',
        query: {
          id: btoa(row.id),
          cardId: btoa(row.idCardNo),
          queryMonth: btoa(row.date)
        }
      })
    },
    getData() {
      const query = this.createQuery()
      getEmployeeSalary(query).then((res: any) => {
        this.tableData = res.list || []
        this.tableData.forEach((row: any) => {
          row.date = dayjs(row.date).format('YYYY-MM')
        })
        this.pageInfo.total = res.total
      })
    },

    async onExport() {
      if (!this.searchList.queryMonth.value) {
        this.$message.error('请选择时间')
        return
      }
      const result = await payrollRecordOwnExport({
        queryMonth: this.searchList.queryMonth.value,
        searchText: this.searchList.searchText.value,
      }, {
        loading: true
      })
      if (!result.fileUrl && result.exportStatus === 0) {
        store.commit('getLongPollingFile', result)
      } else {
        axios({
          url: result.fileUrl,
          method: 'GET',
          responseType: 'blob'
        }).then(async (response: any) => {
          await downLoadFile(response, result.exportType, result.updateTime, result.remark)
          await this.$message.success('导出成功！')
        })
      }
    },
    async handleExport(row: any) {
      // console.log(row, 'hyusfhuyg')
      // this.onExport([row.id])
      // if (!this.searchList.queryMonth.value) {
      //   this.$message.error('请选择时间')
      //   return
      // }
      const result = await payrollRecordOwnExport({
        queryMonth: this.searchList.queryMonth.value,
        searchText: row.userName
      }, {
        loading: true
      })
      if (!result.fileUrl && result.exportStatus === 0) {
        store.commit('getLongPollingFile', result)
      } else {
        axios({
          url: result.fileUrl,
          method: 'GET',
          responseType: 'blob'
        }).then(async (response: any) => {
          await downLoadFile(response, result.exportType, result.updateTime, result.remark)
          await this.$message.success('导出成功！')
        })
      }
    }
  }
})
