import { QueryDateSlotDto } from './dto'
import request from './request'
// 停止发薪
export function stopPay(ids: string): Promise<string> {
    return request.put('/rest/pay/pay-payment-record/updateStatus/' + ids)
}

// 申请回单（微信）
export function applyBill(data: {
    batchNo: string;
    detailOrderNo: string;
    id: number;
}): Promise<void> {
    return request.post('/rest/pay/pay-payment-record/billApply', data)
}

// 下载回单（微信）
export function downloadBill(data: {
    batchNo: string;
    detailOrderNo: string;
    id: number;
}): Promise<string> {
    return request.post(
        '/rest/pay/pay-payment-record/queryBillDownloadUrl',
        data
    )
}

// 批量申请回单（微信）
export function batchApplyBill(params: {
    processId: number;
    ids: string;
}): Promise<void> {
    return request.get('/rest/pay/pay-payment-record/billApplyMore', {
        params
    })
}

// 批量下载回单（微信）
export function batchDownloadBill(params: {
    processId: number;
    ids: string;
}): Promise<void> {
    return request.get('/rest/pay/pay-payment-record/billDownloadMore', {
        params
    })
}

interface EleDto extends QueryDateSlotDto {
    searchText?: string;
    ids: string;
    createUserName?: string;
}

// 批量生成系统回单
export function batchApplyEle(params: EleDto): Promise<void> {
    return request.get('/rest/pay/processOrder/eleApply', {
        params
    })
}

// 批量下载系统回单
export function batchDownloadEle(params: EleDto): Promise<string> {
    return request.get('/rest/pay/processOrder/eleDownload', {
        params
    })
}
// 生成单条系统回单
export function applyEle(orderId: string): Promise<string> {
    return request.get('/rest/pay/processOrder/pdf-create/' + orderId)
}

// 申请领薪记录回单
interface GetPayrollRecordEleDto {
    ids: string;
    beginPayTime: string;
    endPayTime: string;
    beginGetSalaryTime: string;
    endGetSalaryTime: string;
}
export function payrollRecordEle(
    data: GetPayrollRecordEleDto
): Promise<string> {
    return request.get('/rest/pay/pay-payment-record/billApplyMore', {
        params: data
    })
}
// 下载领薪记录回单 /rest/pay/pay-payment-record/billDownloadMore
export function downloadPayrollRecordEle(data: {
    ids: string;
}): Promise<string> {
    return request.get('/rest/pay/pay-payment-record/billDownloadMore', {
        params: data
    })
}

export function downloadPayrollRecordElepersonal(data: {
    beginPayTime: string;
    endPayTime: string;
    idCardNo: string;
    staus: number;
    isError:number
}): Promise<string> {
    return request.get('/rest/pay/pay-payment-record/billDownloadMore', {
        params: data
    })
}

// 生成个人维度回单信息
export function downloadCreatePersonnelReceipt(data: {
    status: string;
    beginPayTime: string;
    endPayTime: string;
    idCardNo: string;
}): Promise<string> {
    return request.get('/pay-module-export/create-personnel-receipt', {
        params: data
    })
}
