import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"
import _imports_0 from '@/assets/icon-male.png'
import _imports_1 from '@/assets/icon-female.png'


const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "head flex flex-ai-c" }
const _hoisted_3 = { class: "fs-16 bold flex flex-ai-c" }
const _hoisted_4 = {
  key: 0,
  src: _imports_0,
  alt: "",
  class: "icon-gender"
}
const _hoisted_5 = {
  key: 1,
  src: _imports_1,
  alt: "",
  class: "icon-gender"
}
const _hoisted_6 = { class: "body mg-t-20" }
const _hoisted_7 = { class: "flex mg-b-12" }
const _hoisted_8 = { class: "w-300" }
const _hoisted_9 = { class: "bold" }
const _hoisted_10 = { class: "w-300" }
const _hoisted_11 = { class: "bold" }
const _hoisted_12 = { class: "w-300" }
const _hoisted_13 = { class: "bold" }
const _hoisted_14 = { class: "flex-2" }
const _hoisted_15 = { class: "bold" }
const _hoisted_16 = { class: "flex" }
const _hoisted_17 = { class: "w-300" }
const _hoisted_18 = { class: "bold" }
const _hoisted_19 = { class: "w-300" }
const _hoisted_20 = { class: "bold" }
const _hoisted_21 = { class: "w-300" }
const _hoisted_22 = { class: "bold" }
const _hoisted_23 = { class: "flex-2" }
const _hoisted_24 = { class: "bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_card = _resolveComponent("el-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_card, { shadow: "never" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("span", null, _toDisplayString(_ctx.detail.userName), 1),
            (_ctx.detail.sex === 0)
              ? (_openBlock(), _createElementBlock("img", _hoisted_4))
              : _createCommentVNode("", true),
            (_ctx.detail.sex === 1)
              ? (_openBlock(), _createElementBlock("img", _hoisted_5))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _cache[0] || (_cache[0] = _createElementVNode("span", { class: "fc-lightgray" }, "月发薪单数：", -1)),
              _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.setDefault(_ctx.detail.totalPayCount)), 1)
            ]),
            _createElementVNode("div", _hoisted_10, [
              _cache[2] || (_cache[2] = _createElementVNode("span", { class: "fc-lightgray" }, "月已领金额：", -1)),
              _createElementVNode("span", _hoisted_11, [
                _cache[1] || (_cache[1] = _createElementVNode("span", null, "¥", -1)),
                _createTextVNode(_toDisplayString(_ctx.setDefault(_ctx.detail.received)), 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_12, [
              _cache[4] || (_cache[4] = _createElementVNode("span", { class: "fc-lightgray" }, "月停发金额：", -1)),
              _createElementVNode("span", _hoisted_13, [
                _cache[3] || (_cache[3] = _createElementVNode("span", null, "¥", -1)),
                _createTextVNode(_toDisplayString(_ctx.setDefault(_ctx.detail.stopPay)), 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_14, [
              _cache[5] || (_cache[5] = _createElementVNode("span", { class: "fc-lightgray" }, "电话号码：", -1)),
              _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.detail.phone), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_16, [
            _createElementVNode("div", _hoisted_17, [
              _cache[7] || (_cache[7] = _createElementVNode("span", { class: "fc-lightgray" }, "月实发金额：", -1)),
              _createElementVNode("span", _hoisted_18, [
                _cache[6] || (_cache[6] = _createElementVNode("span", null, "¥", -1)),
                _createTextVNode(_toDisplayString(_ctx.setDefault(_ctx.detail.realHairAmount)), 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_19, [
              _cache[9] || (_cache[9] = _createElementVNode("span", { class: "fc-lightgray" }, "月待领金额：", -1)),
              _createElementVNode("span", _hoisted_20, [
                _cache[8] || (_cache[8] = _createElementVNode("span", null, "¥", -1)),
                _createTextVNode(_toDisplayString(_ctx.setDefault(_ctx.detail.unclaimed)), 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_21, [
              _cache[11] || (_cache[11] = _createElementVNode("span", { class: "fc-lightgray" }, "月失败金额：", -1)),
              _createElementVNode("span", _hoisted_22, [
                _cache[10] || (_cache[10] = _createElementVNode("span", null, "¥", -1)),
                _createTextVNode(_toDisplayString(_ctx.setDefault(_ctx.detail.getSalaryFail)), 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_23, [
              _cache[12] || (_cache[12] = _createElementVNode("span", { class: "fc-lightgray" }, "身份证号：", -1)),
              _createElementVNode("span", _hoisted_24, _toDisplayString(_ctx.detail.idCardNo), 1)
            ])
          ])
        ])
      ]),
      _: 1
    })
  ]))
}